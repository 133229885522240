export default {
  install(Vue){
    Vue.prototype.global = {
      ANSWER_STATUS:{
        PART: 1,
        FINISHED: 2,
      },
      EXAM_TYPE: {
        NORMAL: 1,
        TEST: 2
      }
    };
  }
}
