<template>
  <a-layout class="container">
    <a-layout-content class="content">
      <div style="margin-top: 5rem;">
        <img :src="require('../assets/logo/logo-main.gif')" height="40" alt="华文水平测试" />
      </div>
      <div class="panel">
        <h1 class="title">欢迎使用华文水平测试日志组件</h1>
        <p class="lead">请访问正确的链接</p>
      </div>
    </a-layout-content>
    <a-layout-footer class="footer">
      暨南大学华文学院华文考试院 版权所有
    </a-layout-footer>
  </a-layout>
</template>

<script>

export default {
  name: 'HomeView',
  data() {
    return {

    }
  },
  methods: {
  }
}
</script>
<style scoped>
.container {
  min-height: 100%;
  text-align: center;
}
.panel{
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  margin-left: -40%;
  margin-top: -230px;
  background-color: #fff;
  border: solid 1px #ddd;
  padding: 4rem 2rem;
}
.title {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.footer {

}
@media (max-width: 540px){
  .title {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  .panel{
    position: absolute;
    width: 340px;
    left: 50%;
    top: 50%;
    margin-left: -170px;
    margin-top: -200px;
    background-color: #fff;
    border: solid 1px #ddd;
    padding: 3rem 1rem;
  }
}
</style>
