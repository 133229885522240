import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/:examType/:examCode",
    redirect: "/:examType/:examCode/login"
  },
  {
    path: '/:examType/:examCode/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: "/:examType/:examCode/exam",
    name: "exam",
    component: () => import('../views/ExamView.vue')
  },
  {
    path: "/Page/NotFound",
    name: "NotFound",
    component: () => import('../views/PageNotFoundView.vue')
  },
  {
    path: "*",
    redirect: "/Page/NotFound"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
